<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-button
        variant="outline-primary"
        :to="{
          name: 'booking-session-all-session-new',
        }"
      >
        Book Session
      </b-button>
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,BButton
} from 'bootstrap-vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,
    BButton,
    BNavbarNav,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
